import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import Subscribe from "../elements/subscribe"

const PrivacyPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "privacy" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
        description="At New Macau Casino, our guests' privacy is of the upmost importance. We never share any guest information with any 3rd parties."
      />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "privacy_title" })}
        sliderSubtitle={intl.formatMessage({ id: "privacy_subtitle" })}
        objectPosition="25% 50%"
      />
      <Subscribe />
      <div className="privacyPage">
        <div>
          <h1>{intl.formatMessage({ id: "privacyPolicy_title" })}</h1>
          <p>{intl.formatMessage({ id: "privacyPolicy_text1" })}</p>

          <p>{intl.formatMessage({ id: "privacyPolicy_text2" })}</p>

          <h2> {intl.formatMessage({ id: "privacyPolicy_text3" })}</h2>

          <p>{intl.formatMessage({ id: "privacyPolicy_text4" })}</p>

          <h2> {intl.formatMessage({ id: "privacyPolicy_text5" })}</h2>

          <h4> {intl.formatMessage({ id: "privacyPolicy_text6" })}</h4>

          <p>{intl.formatMessage({ id: "privacyPolicy_text7" })}</p>

          <ul>
            <p>
              <li> {intl.formatMessage({ id: "privacyPolicy_text8" })}</li>
            </p>
          </ul>

          <h4> {intl.formatMessage({ id: "privacyPolicy_text9" })}</h4>

          <p>{intl.formatMessage({ id: "privacyPolicy_text10" })}</p>

          <h2> {intl.formatMessage({ id: "privacyPolicy_text11" })}</h2>
          <p>{intl.formatMessage({ id: "privacyPolicy_text12" })}</p>
          <h4> {intl.formatMessage({ id: "privacyPolicy_text13" })}</h4>
          <ul>
            <p>
              <li>
                <strong>
                  {" "}
                  {intl.formatMessage({ id: "privacyPolicy_text14" })}
                </strong>{" "}
                {intl.formatMessage({ id: "privacyPolicy_text15" })}
              </li>
            </p>
            <p>
              <li>
                <strong>
                  {" "}
                  {intl.formatMessage({ id: "privacyPolicy_text16" })}
                </strong>{" "}
                {intl.formatMessage({ id: "privacyPolicy_text17" })}
              </li>
            </p>
            <p>
              <li>
                <strong>
                  {" "}
                  {intl.formatMessage({ id: "privacyPolicy_text18" })}
                </strong>{" "}
                {intl.formatMessage({ id: "privacyPolicy_text19" })}
              </li>
            </p>
          </ul>

          <h2> {intl.formatMessage({ id: "privacyPolicy_text21" })}</h2>

          <p>{intl.formatMessage({ id: "privacyPolicy_text22" })}</p>
          <ul>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text23" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text24" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text25" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text26" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text27" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text28" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text29" })}</li>
            </p>
          </ul>

          <h2>{intl.formatMessage({ id: "privacyPolicy_text30" })}</h2>
          <p>{intl.formatMessage({ id: "privacyPolicy_text31" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text32" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text33" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text34" })}</p>

          <h2>{intl.formatMessage({ id: "privacyPolicy_text35" })}</h2>

          <h4>{intl.formatMessage({ id: "privacyPolicy_text36" })}</h4>
          <p>{intl.formatMessage({ id: "privacyPolicy_text37" })}</p>
          <ul>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text38" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text39" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text40" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text41" })}</li>
            </p>
            <p>
              <li>{intl.formatMessage({ id: "privacyPolicy_text42" })}</li>
            </p>
          </ul>

          <h2>{intl.formatMessage({ id: "privacyPolicy_text43" })}</h2>
          <p>{intl.formatMessage({ id: "privacyPolicy_text44" })}</p>

          <h4>{intl.formatMessage({ id: "privacyPolicy_text45" })}</h4>
          <p>{intl.formatMessage({ id: "privacyPolicy_text46" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text47" })}</p>

          <h2>{intl.formatMessage({ id: "privacyPolicy_text48" })}</h2>
          <p>{intl.formatMessage({ id: "privacyPolicy_text49" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text50" })}</p>

          <h2>{intl.formatMessage({ id: "privacyPolicy_text51" })}</h2>
          <p>{intl.formatMessage({ id: "privacyPolicy_text52" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text53" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text54" })}</p>

          <h2>{intl.formatMessage({ id: "privacyPolicy_text55" })}</h2>
          <p>{intl.formatMessage({ id: "privacyPolicy_text56" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text57" })}</p>
          <p>{intl.formatMessage({ id: "privacyPolicy_text58" })}</p>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(PrivacyPage)
